import { helpers } from '@vuelidate/validators';
import { Delta } from '@vueup/vue-quill';
import dayjs, { type ConfigType } from 'dayjs';

export const mustBeTrue = (value: unknown): boolean => value === true;

export const UK_MIN_LATITUDE = 49;
export const UK_MAX_LATITUDE = 61;
export const UK_MIN_LONGITUDE = -8;
export const UK_MAX_LONGITUDE = 2;

export const postcode = helpers.regex(
  /^(X99BU)|(X9 9BU)|(GIR 0AA)|(((A[BL]|B[ABDHLNRSTX]?|C[ABFHMORTVW]|D[ADEGHLNTY]|E[HNX]?|F[KY]|G[LUY]?|H[ADGPRSUX]|I[GMPV]|JE|K[ATWY]|L[ADELNSU]?|M[EKL]?|N[EGNPRW]?|O[LX]|P[AEHLOR]|R[GHM]|S[AEGKLMNOPRSTY]?|T[ADFNQRSW]|UB|W[ADFNRSV]|YO|ZE)[1-9]?[0-9]|((E|N|NW|SE|SW|W)1|EC[1-4]|WC[12])[A-HJKMNPR-Y]|(SW|W)([2-9]|[1-9][0-9])|EC[1-9][0-9]) [0-9][ABD-HJLNP-UW-Z]{2})$/i,
);

export const humanName = helpers.regex(
  /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/g,
);

export const ukMobileNumber = helpers.regex(
  /^((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}$/,
);

export const multipleCSVEmails = helpers.regex(
  /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
);
export const notBeQuillEmptyState = (
  value: Delta | string | null | undefined,
): boolean => value !== '<p><br></p>';

export const isValidDate = (value: ConfigType) =>
  dayjs(value, 'YYYY-MM-DD', true).isValid();

export const afterDate = (date: ConfigType) =>
  helpers.withParams({ type: 'after', value: date }, (value: ConfigType) =>
    dayjs(value).isAfter(date),
  );

export const afterStartDate = (startDate: ConfigType) =>
  helpers.withMessage(
    'The end date cannot be before the start date',
    afterDate(startDate),
  );

export const notZero = (index: ConfigType) => index !== 0;

export const isLatitudeSimple = (num: number) =>
  isFinite(num) && num >= UK_MIN_LATITUDE && num <= UK_MAX_LATITUDE;
export const isLongitudeSimple = (num: number) =>
  isFinite(num) && num >= UK_MIN_LONGITUDE && num <= UK_MAX_LONGITUDE;
