import BoostPropertyStep from '../components/CheckoutSteps/BoostPropertyStep/BoostPropertyStep.vue';
import { useCheckoutStore } from '../stores/index';
import type {
  CheckoutSchema,
  OrderItem,
  PurchaseBoostsPayload,
} from './../types/index';
import checkoutApi from './checkoutApi';
import type { User } from '~/types/user';

const boostCheckoutSchema: CheckoutSchema = {
  title: 'Purchase Boosts',
  successMessage:
    'Congratulations. Your Boosts have been added to your account.',
  component: BoostPropertyStep,
  init: () => {},
  submit: async function (): Promise<void> {
    const checkoutStore = useCheckoutStore();

    if (!checkoutStore.hasOrderItems) {
      throw new Error('Cannot purchase boosts without selecting an item.');
    }

    const orderItem: OrderItem<Domain.Properties.DataObjects.BoostPriceData> =
      checkoutStore.items[0];

    const quantity = orderItem.data?.quantity;

    if (!quantity) {
      throw new Error('Boost quantity cannot be null.');
    }

    const discountCode: string | null = checkoutStore.discountCode;

    const payload: PurchaseBoostsPayload =
      createPurchasePayload<PurchaseBoostsPayload>({
        discount_code: discountCode,
        quantity,
      });

    const { data, error } = await checkoutApi.boost.purchase(payload);

    if (data.value?.status === 'success') {
      const { user }: { user: User } = useAuth();
      user.landlord.boosts = data.value.quantity;

      await checkoutStore.complete();
    }

    if (error.value?.data.status === 'failed') {
      checkoutStore.loading = false;
      throw new Error(error.value.data.message);
    }
  },
};

export default boostCheckoutSchema;
