import type { EventBusKey } from '@vueuse/core';

export const globalKey: EventBusKey<string> = Symbol('global');

export const UPLOAD_UTILITY_ORDER_OPEN = 'uploadUtilityOrder:open';
export const UPLOAD_UTILITY_ORDER_CLOSE = 'uploadUtilityOrder:close';
export const DATA_TABLE_CLEAR_SELECTED_IDS = 'dataTable:clearSelectedIds';

export const REMOVE_USER = 'user:remove';
export const REFRESH_USERS = 'users:refresh';
export const RESEND_INVITE_LINK = 'user:resendInviteLink';

export const STEPPER_FORCE_LOGOUT = 'stepper:forceLogout';
