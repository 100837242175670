import { config, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Free solid icons
import {
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBath as fasBath,
  faBed as fasBed,
  faBell,
  faBuilding,
  faCalendar,
  faChartColumn,
  faChartPie,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faCloudArrowUp,
  faCopy,
  faEllipsisH,
  faEllipsisVertical as fasEllipsisVertical,
  faEnvelope,
  faEquals as fasEquals,
  faExclamation,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileArrowDown,
  faFilePdf as fasFilePdf,
  faFilter,
  faGear,
  faHouse,
  faHouseSignal as fasHouseSignal,
  faInfo,
  faLink,
  faLock,
  faMagnifyingGlass,
  faMask,
  faMoon,
  faPause,
  faPenToSquare,
  faPhone,
  faPlay as fasPlay,
  faPlus,
  faRocket,
  faRotateLeft,
  faRotateRight,
  faSpinner,
  faStar,
  faSterlingSign as fasSterlingSign,
  faTableList,
  faTrashCan,
  faTriangleExclamation,
  faUpload as fasUpload,
  faUser,
  faUserGroup,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

// https://fontawesome.com/docs/web/use-with/vue/add-icons#same-icon-different-styles
// Pro regular icons
import {
  faArrowDown as farArrowDown,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowsRotate as farArrowsRotate,
  faArrowUp as farArrowUp,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faBan as farBan,
  faBell as farBell,
  faBolt as farBolt,
  faBook as farBook,
  faBuilding as farBuilding,
  faBuildings as farBuildings,
  faCalendar as farCalendar,
  faCalendars as farCalendars,
  faChartBar as farChartBar,
  faChartColumn as farChartColumn,
  faChartLine as farChartLine,
  faChartMixed as farChartMixed,
  faChartPie as farChartPie,
  faChartUser as farChartUser,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faCircle as farCircle,
  faCircleCheck as farCircleCheck,
  faCircleDashed as farCircleDashed,
  faCircleQuestion as farCircleQuestion,
  faCircleSterling as farCircleSterling,
  faClipboard as farClipboard,
  faClock as farClock,
  faCommentSms as farCommentSms,
  faDash as farDash,
  faDownload as farDownload,
  faEnvelope as farEnvelope,
  faExclamationCircle as farExclamationCircle,
  faEye as farEye,
  faFileArrowDown as farFileArrowDown,
  faFileArrowUp as farFileArrowUp,
  faFileCertificate as farFileCertificate,
  faFileCircleCheck as farFileCircleCheck,
  faFileCircleExclamation as farFileCircleExclamation,
  faFileCirclePlus as farFileCirclePlus,
  faFileCircleQuestion as farFileCircleQuestion,
  faFileCircleXmark as farFileCircleXmark,
  faFileContract as farFileContract,
  faFileImport as farFileImport,
  faFilePlus as farFilePlus,
  faFiles as farFiles,
  faFilter as farFilter,
  faFloppyDisk as farFloppyDisk,
  faGauge as farGauge,
  faGaugeMax as farGaugeMax,
  faGear as farGear,
  faHouse as farHouse,
  faHouseBuilding as farHouseBuilding,
  faHouseCircleCheck as farHouseCircleCheck,
  faHousePersonReturn as farHousePersonReturn,
  faInbox as farInbox,
  faLightbulb as farLightbulb,
  faLocationPlus as farLocationPlus,
  faLockKeyhole as farLockKeyhole,
  faMagnifyingGlass as farMagnifyingGlass,
  faMoneyBill as farMoneyBill,
  faOctagonXmark as farOctagonXmark,
  faPaperPlane as farPaperPlane,
  faPenToSquare as farPenToSquare,
  faPhone as farPhone,
  faPlus as farPlus,
  faReceipt as farReceipt,
  faRectangleHistoryCircleUser as farRectangleHistoryCircleUser,
  faRocket as farRocket,
  faSlidersSimple as farSlidersSimple,
  faSms as farSms,
  faSparkles as farSparkles,
  faSunBright as farSunBright,
  faTableList as farTableList,
  faTrash as farTrash,
  faUnlockKeyhole as farUnlockKeyhole,
  faUpload as farUpload,
  faUser as farUser,
  faUsers as farUsers,
  faUsersMedical as farUsersMedical,
  faXmark as farXmark,
  faFileSignature as farFileSignature,
  faSignature as farSignature,
  faFileMagnifyingGlass as farFileMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faChartMixed as fasChartMixed,
  faChartPieSimple,
  faCircleDashed as fasCircleDashed,
  faClone,
  faLocationPlus as fasLocationPlus,
  faMessageSms,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faClock
} from '@fortawesome/free-regular-svg-icons';

// This is important, we are going to let Nuxt worry about the CSS
library.add(
  // Solid
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBuilding,
  faCalendar,
  faChartColumn,
  faChartPie,
  faChartPieSimple,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  farCircleSterling,
  faClock,
  faClone,
  faCloudArrowUp,
  faCopy,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileArrowDown,
  faFilter,
  faGear,
  faHouse,
  faInfo,
  faLink,
  faLock,
  faMagnifyingGlass,
  faMask,
  faMessageSms,
  faMoon,
  faPause,
  faPenToSquare,
  faPhone,
  faPlus,
  faRocket,
  faRotateLeft,
  faRotateRight,
  faSpinner,
  faStar,
  faTableList,
  faTrashCan,
  faTriangleExclamation,
  faUser,
  faUserGroup,
  faXmark,
  // Regular
  farOctagonXmark,
  farCircleQuestion,
  farArrowDown,
  farArrowUp,
  farArrowRightFromBracket,
  farArrowsRotate,
  farArrowUpRightFromSquare,
  farBan,
  farBell,
  farBolt,
  farBook,
  farBuilding,
  farBuildings,
  farCalendar,
  farCalendars,
  farChartBar,
  farChartColumn,
  farChartLine,
  farChartMixed,
  farChartPie,
  farChartUser,
  farChevronDown,
  farChevronUp,
  farCircle,
  farCircleCheck,
  farCircleDashed,
  farCircleSterling,
  farClipboard,
  farClock,
  farCommentSms,
  farDash,
  farDownload,
  farEnvelope,
  farExclamationCircle,
  farEye,
  farFileArrowDown,
  farFileArrowUp,
  farFileCertificate,
  farFileCircleCheck,
  farFileCircleExclamation,
  farFileCirclePlus,
  farFileCircleQuestion,
  farFileCircleXmark,
  farFileContract,
  farFileImport,
  farFileMagnifyingGlass,
  farFilePlus,
  farFiles,
  farFilter,
  farFloppyDisk,
  farInbox,
  farGauge,
  farGaugeMax,
  farGear,
  farHouse,
  farHouseBuilding,
  farHouseCircleCheck,
  farHousePersonReturn,
  farLightbulb,
  farLockKeyhole,
  farLocationPlus,
  farMagnifyingGlass,
  farMoneyBill,
  farPaperPlane,
  farPenToSquare,
  farPhone,
  farPlus,
  farReceipt,
  farRectangleHistoryCircleUser,
  farRocket,
  farSlidersSimple,
  farSms,
  farSparkles,
  farSunBright,
  farTableList,
  farTrash,
  farUpload,
  farUnlockKeyhole,
  farUser,
  farUsers,
  farUsersMedical,
  farXmark,
  // Solid
  fasBath,
  fasBed,
  fasEllipsisVertical,
  fasHouseSignal,
  fasSterlingSign,
  fasUpload,
  fasPlay,
  fasFilePdf,
  fasChartMixed,
  fasCircleDashed,
  fasEquals,
  fasLocationPlus,
  farFileSignature,
  farSignature,
);

config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon);
});
