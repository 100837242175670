<script setup lang="ts">
import BoostItemGrid from './BoostItemGrid.vue';
import type { User } from '~/types/user';

const { user }: { user: User } = useAuth();
</script>

<template>
  <div class="space-y-6">
    <div>
      <UhHeading lvl="5">Give your listing a little boost!</UhHeading>
      <p>
        Boosting pushes your properties to the top of the list. Get more
        enquiries by reaching more students.
      </p>
    </div>

    <UhHR class="my-0" />

    <div>
      <p>
        You currently have
        <span class="font-bold">
          {{ (user.landlord.boosts ?? 0).toLocaleString() }} Boosts
        </span>
        available.
      </p>
    </div>

    <UhHR class="my-0" />

    <div class="flex w-full flex-col">
      <UhHeading lvl="5">Buy more Boosts</UhHeading>

      <BoostItemGrid class="mb-2 mt-4" />

      <div class="inline-flex justify-end">
        <label class="text-sm text-gray-500">
          All prices are excluding VAT
        </label>
      </div>
    </div>
  </div>
</template>
